// src/pages/Home.tsx
import React, { useState } from 'react';
import Header from '../components/Header';
import ChatBot from '../components/ChatBot';
import Articles from '../components/Articles';
import Iframe from '../components/Iframe';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import Footer from '../components/Footer';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer2';
import Footer2Mobile from '../components/Footer2_Mobile';
import useWindowSize from '../hooks/useWindowSize';
import Hero from '../components/HeroSection';
import OverlayChatbot from '../components/OverlayChatbot';
import FloatingButton from '../components/FloatingButton';
import floatingChatIcon from "../assets/smiley-comment-alt.png";
import { Height } from '@mui/icons-material';

const Home: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const openChatbot = () => setIsChatbotOpen(true);
  const closeChatbot = () => setIsChatbotOpen(false);

  return (
    <> 
      <Header />
      <SecondHeader title={'Home'}/>
      <Hero/>
      <FloatingButton 
        onClick={openChatbot} 
        icon={<img src={floatingChatIcon} alt="Chat Icon" />} 
      />
      <OverlayChatbot isOpen={isChatbotOpen} onClose={closeChatbot} />
      {/* <Banner /> */}
      {/* <ChatBot /> */}
      <WhyWhatsLegal /> 
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default Home;
